import { getFormattedDate } from "../utility/const";


const initial = {
    selectedDate: sessionStorage.getItem("selectedDate") === null ? getFormattedDate(new Date()) : getFormattedDate(sessionStorage.getItem("selectedDate")),
    selectedShifts: [],
    shiftsList: [],
    selectedMachine: null,
    addNewMachineLine: false,
    machineGroupList: [],
    autoRefresh: 15,
    machineList: [],
    recentList: [],
    overiewData:{},
    status: false
};

export default function FilterStore(state = initial, action) {
    switch (action.type) {
        case "SET_SELECTED_DATE":
            state = {
                ...state,
                selectedDate: getFormattedDate(action.date)
            }
            break;
        case "SET_SHIFTS":
            state = {
                ...state,
                selectedShifts: action.shifts.map((eachItem) => eachItem)
            }
            break;
        case "SET_SHIFTS_LIST":
            state = {
                ...state,
                shiftsList: action.shiftsList
            }
            break;
        case "SET_MACHINE":
            state = {
                ...state,
                selectedMachine: action.machine
            }
            break;
        case "ADD_MACHINE_LINE":
            state = {
                ...state,
                addNewMachineLine: action.newLine
            }
            break;
        case "SET_MACHINE_LINE_LIST":
            state = {
                ...state,
                machineGroupList: action.list
            }
            break;
        case "SET_AUTOREFRESH":
            state = {
                ...state,
                autoRefresh: action.refreshTime
            }
            break;
        case "SET_MACHINE_LIST":
            state = {
                ...state,
                machineList: action.list
            }
            break;
        case "SET_RECENT_LIST":
            state = {
                ...state,
                recentList: action.list
            }
            break;
        case "SET_OVERVIEW_DATA":
            state = {
                ...state,
                overiewData:action.overviewObj
            }    
            break;
        case "SET_USERSETTINGS_STATUS":
            state = {
                ...state,
                status:action.status
            }    
        default:
            break;
    }
    return state;
}

