export const setSelectedDate = (date) => {
    return {
        type: "SET_SELECTED_DATE",
        date
    }
};

export const setSelectedShifts = (shifts) => {
    return {
        type: "SET_SHIFTS",
        shifts
    }
}

export const setShiftsList = (shiftsList) => {
    return {
        type: "SET_SHIFTS_LIST",
        shiftsList
    }
}

export const setSelectedMachine = (machine) => {
    return {
        type: "SET_MACHINE",
        machine
    }
}

export const setAddNewMachineLine = (newLine) => {
    return {
        type: "ADD_MACHINE_LINE",
        newLine
    }
}

export const setMachineGroupList = (list) => {
    return {
        type: "SET_MACHINE_LINE_LIST",
        list
    }
}

export const setRefreshTime = (refreshTime) => {
    return {
        type: "SET_AUTOREFRESH",
        refreshTime
    }
}

export const setMachineList = (list) => {
    return {
        type: "SET_MACHINE_LIST",
        list
    }
}

export const setRecentList = (list) => {
    return {
        type: "SET_RECENT_LIST",
        list
    }
}

export const setOverviewData = (overviewObj) => {
    return {
        type:"SET_OVERVIEW_DATA",
        overviewObj
    }
}

export const setUserSettingsStatus = (status) => {
    return {
        type:"SET_USERSETTINGS_STATUS",
        status
    }
}