import React, { Suspense } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

const LightTheme = React.lazy(() => import('./lighttheme'));
const DarkTheme = React.lazy(() => import('./darktheme'));


export default function ThemeSelector({ children }) {
    const themeName = conversionLib.getLocalStorageValue("core.app.theme") !== null ? (conversionLib.getLocalStorageValue("core.app.theme")) : "LightTheme";

    return (
        <>
            <Suspense fallback={() => null}>
                {themeName === "LightTheme" ? <LightTheme /> : null}
                {themeName === "DarkTheme" ? <DarkTheme /> : null}
            </Suspense>
            {children}
        </>
    )
}
