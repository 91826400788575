import csMessages from "devextreme/localization/messages/cs.json";
import deMessages from "devextreme/localization/messages/de.json";
import elMessages from "devextreme/localization/messages/el.json";
import enMessages from "devextreme/localization/messages/en.json";
import esMessages from "devextreme/localization/messages/es.json";
import fiMessages from "devextreme/localization/messages/fi.json";
import frMessages from "devextreme/localization/messages/fr.json";
import huMessages from "devextreme/localization/messages/hu.json";
import itMessages from "devextreme/localization/messages/it.json";
import jaMessages from "devextreme/localization/messages/ja.json";
import nlMessages from "devextreme/localization/messages/nl.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import ruMessages from "devextreme/localization/messages/ru.json";
import slMessages from "devextreme/localization/messages/sl.json";
import svMessages from "devextreme/localization/messages/sv.json";
import trMessages from "devextreme/localization/messages/tr.json";
import viMessages from "devextreme/localization/messages/vi.json";
import zhMessages from "devextreme/localization/messages/zh.json";
import { locale, loadMessages } from "devextreme/localization";

export const initiateDevExpressLang = (lang) => {
        loadMessages(csMessages);
        loadMessages(deMessages);
        loadMessages(elMessages);
        loadMessages(enMessages);
        loadMessages(esMessages);
        loadMessages(fiMessages);
        loadMessages(frMessages);
        loadMessages(huMessages);
        loadMessages(itMessages);
        loadMessages(jaMessages);
        loadMessages(nlMessages);
        loadMessages(ptMessages);
        loadMessages(ruMessages);
        loadMessages(slMessages);
        loadMessages(svMessages);
        loadMessages(trMessages);
        loadMessages(viMessages);
        loadMessages(zhMessages);
        locale(mapDevexpressLanguage(lang));
}

export const mapDevexpressLanguage = (lang) => {
    let langVal = 'en';
    switch (lang) {
        case "ces": { langVal = 'cs'; break; }
        case "deu": { langVal = 'de'; break; }
        case "ell": { langVal = 'el'; break; }
        case "eng": { langVal = 'en'; break; }
        case "est": { langVal = 'es'; break; }
        case "fin": { langVal = 'fi'; break; }
        case "fra": { langVal = 'fr'; break; }
        case "hun": { langVal = 'hu'; break; }
        case "ita": { langVal = 'it'; break; }
        case "jpn": { langVal = 'ja'; break; }
        case "nld": { langVal = 'nl'; break; }
        case "por": { langVal = 'pt'; break; }
        case "rus": { langVal = 'ru'; break; }
        case "slk": { langVal = 'sl'; break; }
        case "slv": { langVal = 'sv'; break; }
        case "tur": { langVal = 'tr'; break; }
        case "vie": { langVal = 'vi'; break; }
        case "zho": { langVal = 'zh'; break; }
        default: langVal = 'en'; break;
    }
    return langVal
}