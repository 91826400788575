import React                from 'react';
import ReactDOM             from 'react-dom';
import {setLocalStorageValue, getLocalStorageValue} from 'lisecutilityfunctions/lib/commonfunction';
import { LiMultiterm }      from 'lisecutilityfunctions/lib/utilitylib';

import axios                from 'axios';
import * as configApp       from './config';
import loadable             from 'react-loadable';
import LoadingComp          from './mainframe/src/loadingcomponent/loadingcomponent';
import { checkAccessTokenIsValid, getSiteName } from 'lisecutilityfunctions/lib/openapi';

const siteName = getSiteName()
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    require('./config_dev');
} else {
    require('./config_prod');
}

const LoadingComponent = () => <LoadingComp appName={checkAccessTokenIsValid(getLocalStorageValue("config.accessToken." + siteName))
? <LiMultiterm textId="" textVal="lis.analytics" /> : "lis.analytics"} />;

const Mainframe = loadable({
  loader: () => import('./mainframe/index').then(module => module.default),
  loading: LoadingComponent
});

// window.config = {
//     'isOnAzureCloud': true,
//     'authMethod': 'none',
//     'servicenameSubstitution': {'api_v1_dashboard': 'analytics'}
// }

// const setBaseURL = () => {
//     return axios.get("./config.json")
//     .then(function (response) {
//         if(response.data !== undefined){
//             var config = response.data.config;
//             // var baseURL = config.baseURL;
           
//             // if(baseURL === "$$HOST") // to fetch the protocol & host automatically
//             // {
//             //     var webappBase = window.location.protocol + "//" + window.location.host + "/";
//             //     //console.log("DETECTED WEB HOST : " + webappBase);
//             //     baseURL = webappBase;
//             // }

//             // if(baseURL !== undefined && baseURL !== ""){
//             //     setLocalStorageValue("config.baseurl", baseURL);
//             // }

//             if(getLocalStorageValue("core.app.theme") === null){
//                 setLocalStorageValue("core.app.theme", config.theme);
//             }
//         }
//     })
//     .catch(function (error) {
//         console.error(error, "Error in reading config file");

//         // var webappBase = window.location.protocol + "//" + window.location.host + "/";

//         // if(webappBase !== undefined && webappBase !== ""){
//         //     setLocalStorageValue("config.baseurl", webappBase);
//         // }
//     })
// }



const setBaseURL = () => {
    return axios.get("./config_prod.json")
    .then(function (response) {
        if(response.data !== undefined){
            var config = response.data.config;
            if(config.baseURL !== undefined && process.env.NODE_ENV !== 'development'){
                window.config.configURL.baseURL = config.baseURL;
            }
            if(getLocalStorageValue("core.app.theme") === null){
                setLocalStorageValue("core.app.theme", config.theme);
            }
        }
    })
    .catch(function (error) {
        console.error(error, "Error in reading config file");
    })
}

const getBaseURL = async() => {
    // let baseURL = getLocalStorageValue("config.baseurl");
    let isLogin = true;
    if(window.config !== undefined && window.config.authMethod === "none"){
        isLogin = false;
    }

    if(getLocalStorageValue("core.app.theme") === null) {
        setLocalStorageValue("core.app.theme", 'LightTheme');
    }

    Promise.all([setBaseURL()])
    .then(function(results) {
        ReactDOM.render(
            <Mainframe appType={checkAccessTokenIsValid(getLocalStorageValue("config.accessToken." + siteName))
            ? <LiMultiterm textId="" textVal="lis.analytics" /> : "lis.analytics"} configApp={configApp} isLogin={isLogin} />, 
            document.getElementById('root')
        );
    });
}
   
getBaseURL();
