import React from 'react';
import { TreeList, RowDragging, Sorting, Scrolling, Paging, Pager, Column, Selection, HeaderFilter, Editing, RequiredRule, Lookup, Button, FilterRow } from 'devextreme-react/tree-list';

// import { initiateDevExpressLang } from '../../utility/utilitydevexpress';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';

//Usage

{/* <LiTree id="machine" result={getResult} config={configObject} data={orgItems.getOrgItems()} /> */ }

// configObject = {
//     dragDrop : true,
//     editing : true,
//     columnSearch : true,
//     multiSelect : true,
//     pagination : true,
//     defaultNumberOfRows : 10,
//     pageSizes : [5, 10, 15],
//     expandedRowKeys : [1], //Set -1 if don't want anyone already expanded
//     headers : ["FilterId", "levelNo", "sequenceInLevel", "subLevelNo", "machineChainId"]
// }
class LiTree extends React.Component {
    constructor(props) {
        super(props);
        this.onReorder = this.onReorder.bind(this);

        this.language = conversionLib.getLocalStorageValue("core.app.language.id3");
        // initiateDevExpressLang(this.language);

        this.state = {
            dataList: this.props.data,
            allowDropInsideItem: true,
            allowReordering: true,
            serverData: [],
            initialLoading: true,
            transTexts: {}
        };
        this.isVisibleAdd = this.isVisibleAdd.bind(this);
        this.isVisibleDelete = this.isVisibleDelete.bind(this);
    }

    componentDidMount() {
        this.updateData()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                dataList: this.props.data
            })
        }
        let textsObj = {};
        // var tempArrayofText = await getMultitermTexts([99001948, 99031855, 99032492])
        let texts = this.props.colorComapreTexts
        for (let i = 0; i < texts.length; i = i + 1) {
            if (textsObj && texts && texts[i]) {
                textsObj[texts[i].number] = texts[i].text
            }
        }
        if (prevProps.colorComapreTexts !== this.props.colorComapreTexts)
            this.setState({
                transTexts: textsObj
            })
    }

    updateData() {

    }

    cellPrepared(e) {
        if (e.rowType === "data") {
            if (e.column.dataField === "modifiedAlarmTypeId") {
                if (Object.keys(this.state.transTexts).length !== 0) {
                    if (e.data.modifiedAlarmTypeId === this.state?.transTexts["99031855"]) {
                        e.cellElement.style.backgroundColor = "rgb(136,27,76)";
                        e.cellElement.style.color = "white";
                    } else {
                        e.cellElement.style.backgroundColor = "rgb(58,63,68)";
                        e.cellElement.style.color = "white";
                    }
                }
            }
        }
    }

    managingList() {
        this.updateData()
        this.props.result(this.state.dataList, this.state.serverData);
        // console.log("GUG");
    }

    render() {
        return (
            <div className='CapacityPlanning_Visualisation_TreeContainer'>
                {this.state.transTexts !== {} ?
                    <TreeList
                        id={this.props.id}
                        dataSource={this.state.dataList}
                        rootValue={-1}
                        keyExpr="ID"
                        columnAutoWidth={true}
                        showRowLines={true}
                        showBorders={true}
                        parentIdExpr="Head_ID"
                        defaultExpandedRowKeys={this.props.config.expandedRowKeys}
                        onSaved={() => { this.managingList(); }}
                        autoExpandAll={this.props.expandAll}
                        onCellPrepared={(e)  => this.cellPrepared(e)}
                    >
                        {this.props.config.dragDrop ?
                            <RowDragging
                                onDragChange={this.onDragChange}
                                onReorder={this.onReorder}
                                allowDropInsideItem={this.state.allowDropInsideItem}
                                allowReordering={this.state.allowReordering}
                                showDragIcons={false}
                            />
                            : ""}

                        {this.props.config.editing ?
                            <Editing
                                allowUpdating={true}
                                allowDeleting={true}
                                allowAdding={true}
                                mode="cell"
                            />
                            : ""}

                        {this.props.config.columnSearch ?
                            <FilterRow visible={true} />
                            : ""}

                        {this.props.config.columnSearch ?
                            <HeaderFilter visible={true} />
                            : ""}

                        {this.props.config.multiSelect ?
                            <Selection mode="multiple" />
                            : ""}

                        {this.props.config.pagination ?
                            <Sorting
                                mode="none" />
                            : ""}

                        {this.props.config.pagination ?
                            <Scrolling
                                mode="standard" />
                            : ""}

                        {this.props.config.pagination ?
                            <Paging
                                enabled={true}
                                defaultPageSize={this.props.config.defaultNumberOfRows} />
                            : ""}

                        {this.props.config.pagination ?
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={this.props.config.pageSizes}
                                showInfo={true} />
                            : ""}


                        {this.props.config.headers.map(header => {
                            if (header.id === "ID") {
                                return <Column key={header.id} dataField={header.id} allowEditing={false} allowSorting={false} />
                            }
                            if (header.id === "whoTextNo") {
                                return <Column key={header.id} dataField={header.id} allowEditing={false} allowSorting={false} />
                            }
                            if (header.id === "messageTextNo") {
                                return <Column key={header.id} dataField={header.id} caption={header.caption} allowEditing={false} allowSorting={false} />
                            }
                            if (header.id === "alarmTypeId") {
                                return <Column key={header.id} dataField={header.id} allowEditing={false} allowSorting={false} >
                                    <Lookup
                                        dataSource={this.props.lookupData[0]}
                                        valueExpr="alarmTypeId"
                                        displayExpr="alarmTypeId" />
                                </Column>
                            }
                            if (header.id === "modifiedAlarmTypeId") {
                                return <Column key={header.id} dataField={header.id} caption={header.caption} allowSorting={false} >
                                    <Lookup
                                        dataSource={this.props.lookupData[0]}
                                        valueExpr="modifiedAlarmTypeId"
                                        displayExpr="modifiedAlarmTypeId" />
                                    <RequiredRule />
                                </Column>
                            }
                            return <Column key={header.id} dataField={header.id} allowSorting={false} ></Column>
                        })}

                        <Column type="buttons">
                            <Button name="add" visible={false} />
                            <Button name="delete" visible={false} />
                        </Column>

                        {/* <Template name="imageTemplate" render={() => Images(this.state.dataList)} /> */}

                    </TreeList>
                    : ""}

            </div>
        );
    }

    isVisibleAdd({ row }) {
        if (this.props.plantAssignmentRule) {
            if (row.level === 0) {
                return false
            } else if (row.level === 2) {
                return false
            }
        }
        return true;
    }

    isVisibleDelete({ row }) {
        // if(this.props.plantAssignmentRule){
        //     if(row.level === 0){
        //         return false
        //     } else if (row.level === 1){
        //         return false
        //     }
        // }
        // return true;
    }

    onDragChange(e) {
        let visibleRows = e.component.getVisibleRows(),
            sourceNode = e.component.getNodeByKey(e.itemData.ID),
            targetNode = visibleRows[e.toIndex].node;

        while (targetNode && targetNode.data) {
            if (targetNode.data.ID === sourceNode.data.ID) {
                e.cancel = true;
                break;
            }
            targetNode = targetNode.parent;
        }
    }

    onReorder(e) {
        const visibleRows = e.component.getVisibleRows();
        let sourceData = e.itemData;
        let dataList = this.state.dataList;
        const sourceIndex = dataList.indexOf(sourceData);

        if (e.dropInsideItem) {
            const parentNode = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetNode = parentNode >= 0 ? visibleRows[parentNode].node.data : null;
            const possibleDrop = ["2", "3", "4", "-2", "-3", "-4"];
            let droppedRowID = visibleRows[e.toIndex].data.ID;
            if (this.props.plantAssignmentRule && !possibleDrop.includes(droppedRowID)) {
                return
            } else {
                if (this.props.plantAssignmentRule) {
                    sourceData.priority = null
                }
                sourceData = { ...sourceData, Head_ID: visibleRows[e.toIndex].key };
                dataList = [...dataList.slice(0, sourceIndex), sourceData, ...dataList.slice(sourceIndex + 1)];
            }
        } else {
            const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
            let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;
            if (targetData && e.component.isRowExpanded(targetData.ID)) {
                if (this.props.plantAssignmentRule) {
                    if (targetData.ID === 1 || targetData.ID === 0 || targetData.Head_ID === -1) return
                } else {
                    sourceData = { ...sourceData, Head_ID: targetData.ID };
                    targetData = null;
                }
            } else {
                const headId = targetData ? targetData.Head_ID : -1;
                if (sourceData.Head_ID !== headId) {
                    if (this.props.plantAssignmentRule) {
                        return
                    } else {
                        sourceData = { ...sourceData, Head_ID: headId };
                    }
                }
            }

            dataList = [...dataList.slice(0, sourceIndex), ...dataList.slice(sourceIndex + 1)];

            const targetIndex = dataList.indexOf(targetData) + 1;
            dataList = [...dataList.slice(0, targetIndex), sourceData, ...dataList.slice(targetIndex)];
        }
        this.setState({
            dataList: dataList
        }, () => {
            this.updateData()
        });
    }
}

export default LiTree;
