import React from 'react';
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib';
// import OEEDashboard from './app_modules/oeedashboard/oeeindex'
import OEEDashboard from './app_modules/oeedashboard/oeeindex'
import MachineStageChanges from './app_modules/oeedashboard/oeeindex';
import MachineAndLineManagement from './app_modules/oeedashboard/oeeindex';
import AlarmCategoryManagement from './app_modules/oeedashboard/oeeindex';
import loadable from 'react-loadable';
import { LiPreloader } from 'liseccomponents';
import dashboardIcon from './app_modules/dashboard/images/dashboard.svg';
import calendarIcon from './app_modules/oeedashboard/menuimages/production_calendar.svg';
import AlarmCategoryIcon from "./app_modules/oeedashboard/menuimages/alarm_category_management.svg";
import EventIcon from "./app_modules/oeedashboard/menuimages/event_log.svg";
import MachineAndLineManagementIcon from "./app_modules/oeedashboard/menuimages/machine_line_management.svg";
import OEEDashboardIcon from "./app_modules/oeedashboard/menuimages/oee_dashboard.svg";
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
// import {OEEDashBoardLayout} from './app_modules/oeedashboard/oeedashboardlayout';

const LoadingComponent = () => <LiPreloader type="module" toShow={[true]} />

const DashBoardApp = loadable({
    loader: () => import('./app_modules/dashboard/dashboard').then(module => { return module }),
    loading: LoadingComponent
});

const OEEDashBoard = loadable({
    loader: () => import('./app_modules/oeedashboard/oeeindex').then(module => {
        return module
    }),
    loading: LoadingComponent
});

const AnalyticSetup = loadable({
    loader: () => import('./app_modules/analyticsetup/analyticsetup').then(module => { return module }),
    loading: LoadingComponent
});

export const dashboard_route = (param = {}) => {
    return (<DashBoardApp loader={param.props} />);
}

export const oee_dashboard_route = (param = {}) => {
    return (<OEEDashBoard loader={param.props} />);
}

export const analytic_setup = (param = {}) => {
    return (<AnalyticSetup loader={param.props} />);
}

export const machine_stage_changes = (param = {}) => {
    return (<MachineStageChanges loader={param.props} />);
}

export const machine_and_line_management = (param = {}) => {
    return (<MachineAndLineManagement loader={param.props} />);
}

export const alarm_category_management = (param = {}) => {
    return (<AlarmCategoryManagement loader={param.props} />);
}

export const mapRoute = {
    "menu_dashboard_route": dashboard_route,
    "menu_oee_dashboard_route": oee_dashboard_route,
    "menu_analytic_setup": analytic_setup,
    "menu_machine_stage_changes": machine_stage_changes,
    "machine_and_line_management": machine_and_line_management,
    "alarm_category_management": alarm_category_management
}

export const menus = [
    { "key": "menu_dashboard_route", "menu": "Dashboard", "menuMultiterm": <LiMultiterm textId="99023716" textVal="Dashboard" />, "menuIcon": dashboardIcon, "router_link": "/dashboard", "requiredAuthorization": [69,70], "compulsoryAuthorization":[2] },
    { "key": "menu_oee_dashboard_route", "menu": "OEE Dashboard", "menuMultiterm": <LiMultiterm textId="" textVal="OEE Dashboard" />, "menuIcon": OEEDashboardIcon, "router_link": "/oeedashboard", "requiredAuthorization": [70], "compulsoryAuthorization":[2] },
    { "key": "menu_machine_stage_changes", "menu": "Event Log", "menuMultiterm": <LiMultiterm textId="99003007" textVal="Event Log" />, "menuIcon": EventIcon, "router_link": "/event_log", "requiredAuthorization": [70], "compulsoryAuthorization":[2] },
    { "key": "machine_and_line_management", "menu": "Machine And Line Management", "menuMultiterm": <LiMultiterm textId="99032727" textVal="Machine And Line Management" />, "menuIcon": MachineAndLineManagementIcon, "router_link": "/machine_and_line_management", "requiredAuthorization": [70],"compulsoryAuthorization":[2] },
    { "key": "alarm_category_management", "menu": "Alarm categorisation", "menuMultiterm": <LiMultiterm textId="99032728" textVal="Alarm categorisation" />, "menuIcon": AlarmCategoryIcon, "router_link": "/alarm_categorization", "requiredAuthorization": [70],"compulsoryAuthorization":[2] },
    { "key": "menu_analytic_setup", "menu": "Working time calendar", "menuMultiterm": <LiMultiterm textId="99016832" textVal="Working time calendar" />, "menuIcon": calendarIcon, "router_link": "/working_time_calendar", "requiredAuthorization": [70],"compulsoryAuthorization":[2] },
];
