import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { OpenApiClient_ng_user_management_v1, OpenApiClient_analytics_v1, getSiteName, OpenApiBody } from 'lisecutilityfunctions/lib/openapi';
import * as OpenApiConst from 'lisecutilityfunctions/lib/openapi'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { LiStatusManager, LiReactDialog } from 'liseccomponents';
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import "./filter.css"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MachineImage from '../images/machineimage.svg';
import * as FilterActions from '../actions/filteractions'

let siteName = getSiteName();

class MachineSelector extends Component {
    constructor() {
        super();
        this.state = {
            machineGroupPopUp: false,
            machinePopUp: false,
            machines: [],
            currentSelected: this.props?.filterStore.selectedMachine,
            recentSelectedList: [],
            selectedMachineFromUserSettings: null,
            redirect: false
        }
        this.machineGroupPopUp = this.machineGroupPopUp.bind(this);
        this.currentSelectedMachineName = this.currentSelectedMachineName.bind(this);
    }

    componentDidMount() {
        if (!this.props.isMobile && window.innerWidth > 1023) {
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_UserSettings(this.machineGroupCallback.bind(this));
        }
        else if (this.props.isMobile && window.innerWidth <= 1023) {
            OpenApiClient_ng_user_management_v1.getClient(siteName).GET_UserSettings(this.machineGroupCallback.bind(this));
        }
    }

    machineGroupCallback(responseData) {
        if (responseData.state.response.status === 200) {
            this.props.loader.showLoader({ "GET_Machines": true })
            this.props.setUserSettingsStatus(false)    
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            jsonData.forEach(data => {
                if (data.keyName === "selectedMachine") {
                    this.setState({
                        selectedMachineFromUserSettings: data.value,
                    })
                }
            })
            this.setState({
                userSettings: jsonData
            })
            OpenApiClient_analytics_v1.getClient(siteName).GET_Machine(this.getMachineGroupCallback.bind(this, "MACHINE"));
            OpenApiClient_analytics_v1.getClient(siteName).GET_MachineGroup_siteId(this.getMachineGroupCallback.bind(this, "MACHINE_GROUP"), 1);
        }
        else {
            this.setState({
                userSettingsFailed : true
            })
            this.props.setUserSettingsStatus(true)
        }
    }

    getMachineGroupCallback(type, responseData) {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            if (type === "MACHINE") {
                this.props.setMachineList(jsonData);
                let selectedMachine = jsonData?.filter((data) => data?.machineId === this.state.selectedMachineFromUserSettings);
                let machine = jsonData?.filter((data) => data?.machineId === this.state.selectedMachineFromUserSettings)
                if(machine?.length > 0){
                   this.handleMachineCall(machine[0], "MACHINE");
                   return
                }
                // console.log(jsonData);
                else if (this.state.selectedMachineFromUserSettings !== null && selectedMachine?.length > 0 && machine.length <= 0) {
                    // this.props.setAddNewMachineLine(false)
                    this.props.setSelectedMachine(this.state.selectedMachineFromUserSettings);
                    window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${this.state.selectedMachineFromUserSettings}`)
                } else if (this.state.selectedMachineFromUserSettings === null) {
                    // this.props.setAddNewMachineLine(false)
                    this.props.setSelectedMachine(jsonData[0].machineId);
                    window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${jsonData[0].machineId}`)
                }
                let tempObj = jsonData.filter(data => data.machineId === this.props.filterStore.selectedMachine);
                let recentList = JSON.parse(sessionStorage.getItem("recentList"));
                recentList = recentList.length > 0 ? recentList.filter(item => item !== null) : [];
                this.props.setRecentList((recentList !== null && recentList.length) > 0 ? recentList : [tempObj[0]].concat(this.props.filterStore.recentList))
                sessionStorage.setItem("recentList", JSON.stringify([tempObj[0]].concat(this.props.filterStore.recentList)));
            } else if (type === "MACHINE_GROUP") {
                this.props.setMachineGroupList(jsonData);
                let selectedMachineGroup = jsonData?.filter((data) => data?.groupId === parseInt(this.state.selectedMachineFromUserSettings));
                let machineGroup = jsonData?.filter((data) => data?.groupId === parseInt(this.state.selectedMachineFromUserSettings))
                if(machineGroup.length > 0){
                    this.handleMachineCall(machineGroup[0], "MACHINE_GROUP")
                    return
                }

                else if (this.state.selectedMachineFromUserSettings !== null && selectedMachineGroup?.length > 0) {
                    // this.props.setAddNewMachineLine(false)
                    this.props.setSelectedMachine(this.state.selectedMachineFromUserSettings)
                    window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${this.state.selectedMachineFromUserSettings}`);
                } else if (this.state.selectedMachineFromUserSettings === null) {
                    // this.props.setAddNewMachineLine(false)
                    this.props.setSelectedMachine(jsonData[0].groupId)
                    window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${jsonData[0].groupId}`)
                }
                let tempObj = jsonData.filter(data => String(data.groupId) === this.props.filterStore.selectedMachine);
                tempObj[0].machineId = tempObj[0].groupId
                tempObj[0].machineName = tempObj[0].name
                let recentList = JSON.parse(sessionStorage.getItem("recentList"))
                recentList = recentList.length > 0 ? recentList.filter(item => item !== null) : [];
                this.props.setRecentList((recentList !== null && recentList.length) > 0 ? recentList : [tempObj[0]].concat(this.props.filterStore.recentList))
                sessionStorage.setItem("recentList", JSON.stringify([tempObj[0]].concat(this.props.filterStore.recentList)));
            }
        }
        else {
            // let errorData = conversionLib.convertToJSON(responseData?.state?.response?.data);
            LiStatusManager.showStatus(responseData.state?.response?.status, responseData.state?.response?.status);
        }
        this.props.loader.showLoader({ "GET_Machines": false })
    }

    machineGroupPopUp() {
        this.setState({ machineGroupPopUp: true });
        this.props.setHide(true)
        const ele = document.getElementById('LiLayout_appmenu');
        if(ele){
            ele.style.zIndex = 0
        }
    }

    closePopUp() {
        this.setState({ machineGroupPopUp: false });
        this.props.setHide(false)
        const ele = document.getElementById('LiLayout_appmenu');
        if(ele){
            ele.style.zIndex = 3
        }
    }

    getUniqueListBy(arr) {
        const seen = new Set();
        const filteredArray = arr.filter(item => {
            if(item){
                const duplicate = seen.has(item.machineId);
                seen.add(item.machineId);
                return !duplicate;
            }
        });
       return filteredArray
    }


    handleMachineCall(machine, type) {
        const element = document.getElementById('LiLayout_appmenu')
        if(element){
            element.style.zIndex = 3;
        }
        this.props.setHide(false)
        this.setState({
            currentSelected : machine?.machineName || machine?.machineId || machine?.name
        })
        this.props.setSelectedMachine(machine?.machineId || machine?.groupId)
        if( type === "MACHINE"){
            window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${machine?.machineId}`);
        }
        if (type === "MACHINE_GROUP") {
            machine.machineId = machine.groupId
            machine.machineName = machine.name
            window.history.pushState("", "", `${window.location.origin}${window.location.pathname}?machineId=${machine?.machineId}`);
        }
        // let tempArray = this.state.recentSelectedList;
        
        let tempArray = sessionStorage.getItem('recentList') ? JSON.parse(sessionStorage.getItem('recentList')) : [machine];
        tempArray.unshift(machine)
        let filteredArray = this.getUniqueListBy(tempArray);
        let removedNullIfAny = filteredArray.filter((item) => item !== null)
        tempArray = removedNullIfAny

        if (tempArray.length > 5) {
            tempArray.length = 5
        }
        const recentSelected = tempArray
        // const filteredArray = this.getUniqueListBy(recentSelected, "machineId")
        this.props.setRecentList(recentSelected)
        sessionStorage.setItem("recentList", JSON.stringify(recentSelected));
        this.setState({
            machineGroupPopUp: false,
            // recentSelectedList: recentSelected
        })
        let currentSelectedMachine = {
            keyName: "selectedMachine",
            value: machine.machineId,
            name: machine.machineName
        }
        this.props.setAddNewMachineLine(false)
        
        if(this.props.onMachineSelected !== undefined) {
            this.props.onMachineSelected()
        }
        OpenApiClient_ng_user_management_v1.getClient(siteName).PATCH_UserSettings_batch(this.callbackUserSettings.bind(this), new OpenApiBody(OpenApiConst.MimeTypes.MIME_APPLICATION_JSON, [currentSelectedMachine]))
    }

    callbackUserSettings(responseData) {
        if (responseData.state.response.status === 200) {

        }
        else {
            let errorData = conversionLib.convertToJSON(responseData?.state?.response?.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.title);
        }
    }

    getMachineCallback(responseData) {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            this.setState({
                machines: jsonData
            })
        }
        else {
            let errorData = conversionLib.convertToJSON(responseData?.state?.response?.data);
            LiStatusManager.showStatus(responseData.state.response.status, errorData.title);
        }
    }

    handleRedirect = () => {
        // setAddNewMachineLine
        this.props.setAddNewMachineLine(true)
        this.setState({
            redirect: true,
            // machineGroupPopUp : false
        }, () => {
            this.setState({
                machineGroupPopUp: false
            })
        })
    }

    currentSelectedMachineName = () => {
        if (isNaN(parseInt(this.props.filterStore.selectedMachine))) {
            let machine = this.props.filterStore.machineList.filter(data => this.props.filterStore.selectedMachine === data.machineId);
            // this.props.filterStore.recentList.length > 0 && this.props.filterStore.recentList.map(data => {
            //     if (data?.machineId === this.props.filterStore.selectedMachine) {
            //         data.machineName = machine[0]?.machineName
            //     }
            // })
            return machine[0]?.machineName
        } else {
            let machine = this.props.filterStore.machineGroupList.filter(data => parseInt(this.props.filterStore.selectedMachine) === parseInt(data.groupId))
            // this.props.filterStore.recentList.length > 0 && this.props.filterStore.recentList.map(data => {
            //     if (data?.machineId === this.props.filterStore.selectedMachine) {
            //         data.machineName = machine[0]?.name
            //     }
            // })
            return machine[0]?.name
        }
    }

    render() {
        let recentListFromSession = sessionStorage.getItem('recentList') ? JSON.parse(sessionStorage.getItem("recentList")) : [];
        let list = (recentListFromSession !== null && recentListFromSession !== undefined && recentListFromSession.length) > 0 ? recentListFromSession.filter(element => {
            return (element !== undefined && element !== null)
        }) : this.props.filterStore.recentList.filter(element => {
            return (element !== undefined && element !== null)
        })
        //Filtering Same Objects
        const uniqueIds = []
        let filteredList = list.filter(data => String(data?.machineId) !== String(this.props.filterStore.selectedMachine))
        const uniqueList = filteredList.filter(element => {
            const isDuplicate = uniqueIds.includes(element?.machineId);
            if (!isDuplicate) {
                uniqueIds.push(element?.machineId);
                return true;
            }
            return false;
        });
    
        const LoadMachineList = ( 
            <div className='MachinesContainer'>
                {this.state.redirect ? <Redirect to="machine_and_line_management" /> : ""}
                <span className='MachineGropusHeading'>
                    <LiMultiterm textId="99002832" textVal="MACHINES GROUPS" /> :
                </span>
                <span className='MachinesContainerAddALine' onClick={this.handleRedirect}>
                    <LiMultiterm textId="99033144" textVal="Add line" />
                </span>
                <div className='MachineGroupContainer'>
                    {this.props.filterStore.selectedMachine !== null !== null && this.props.filterStore.machineGroupList.map((machineGroup, index) => {
                        if (parseInt(machineGroup.groupId) === parseInt(this.props.filterStore.selectedMachine)) {
                            return <div key={"MachineGroup" + index} className='MachineGroupSelected MachineTile' onClick={this.handleMachineCall.bind(this, machineGroup, "MACHINE_GROUP")}>
                                <img className="MachineImage" src={(machineGroup?.machineImage !== "" && machineGroup?.machineImage !== null && machineGroup?.machineImage !== undefined) ? "data:image/jpeg;base64," + machineGroup?.machineImage : MachineImage}/>
                                    <div>
                                        <i>{machineGroup.name}</i>
                                    </div>
                                </div>
                        }
                        else {
                            return <div key={"MachineGroup" + index} className='MachineGroup MachineTile' onClick={this.handleMachineCall.bind(this, machineGroup, "MACHINE_GROUP")}>
                                  <img className="MachineImage" src={(machineGroup?.machineImage !== "" && machineGroup?.machineImage !== null && machineGroup?.machineImage !== undefined) ? "data:image/jpeg;base64," + machineGroup?.machineImage : MachineImage}/>
                                <div>
                                    <i>{machineGroup.name}</i>
                                </div>
                                </div>
                        }
                    })}
                </div>
                <span className='MachineGropusHeading'>
                    <LiMultiterm textId="99000849" textVal="MACHINES" /> :
                </span>
                <div className='MachineGroupContainer'>
                {this.props.filterStore.selectedMachine !== null !== null && this.props.filterStore.machineList.map((machine, index) => {
                        if (String(machine.machineId) === String(this.props.filterStore.selectedMachine)) {
                            return <div key={"Machine" + index} className='MachineGroupSelected MachineTile' onClick={this.handleMachineCall.bind(this, machine, "MACHINE")} >
                                    <img className="MachineImage" src={(machine?.machineImage !== undefined && machine?.machineImage !== null && machine?.machineImage !== "") ? "data:image/jpeg;base64," + machine?.machineImage : MachineImage}/>
                                    <div>
                                        <i>{machine.machineName !== undefined ? machine.machineName : "---"} <br/>{machine.machineId}</i>
                                    </div>
                                </div>
                        }
                        else {
                            return <div key={"Machine" + index} className='MachineGroup MachineTile' onClick={this.handleMachineCall.bind(this, machine, "MACHINE")}>
                                <img className="MachineImage" src={(machine?.machineImage !== undefined && machine?.machineImage !== null && machine?.machineImage !== "") ? "data:image/jpeg;base64," + machine?.machineImage : MachineImage}/>
                                    <div>
                                        <i>{machine.machineName !== undefined ? machine.machineName : "---"} <br/>{machine.machineId}</i>
                                    </div>
                                </div>
                        }
                    })}
                </div>
            </div>
        )
        return (
            <div className='MachineSelector'>
                {
                    !this.props.isMobile ?
                        <>
                            <div>
                                <button onClick={this.machineGroupPopUp} className='MachineSelector_ChooseButton'><LiMultiterm textId="99032170" textVal="Choose Line/Machine ..." /></button>
                            </div>
                            <div className='MachineSelector_SelectedMachine'>
                                <span><LiMultiterm textId="99032169" textVal="Current Machine" />:</span>
                                <div onClick={() => { }} className='MachineSelector_SelectedMachineData'>
                                    <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                        <img className='MachineImage' src={ (this?.props?.filterStore?.overiewData?.machineImage !== undefined && this?.props?.filterStore?.overiewData?.machineImage !== "" && this?.props?.filterStore?.overiewData?.machineImage !== null ) ? "data:image/jpeg;base64," +  this?.props?.filterStore?.overiewData?.machineImage : MachineImage} />
                                        <div>
                                            {/* <span>{this.currentSelectedMachineName()} </span> */}
                                            <div>{this.props.filterStore.selectedMachine === null ? "None Selected" : this.state.currentSelected}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='MachineSelector_RecentMachine'>
                                <span><LiMultiterm textId="99032168" textVal="Recent Machine" />:</span>
                                {
                                    uniqueList.length > 0 && uniqueList.map((recentList, index) => {
                                        return <div key={"recentSelectedList" + index} onClick={this.handleMachineCall.bind(this, recentList, "MACHINE")} className='MachineSelector_RecentMachineData'>
                                            <div style={{display:'flex', alignItems:'center', gap:"5px", cursor:'pointer'}}>
                                                <img className='MachineImage' src={(recentList?.machineImage!== "" && recentList?.machineImage !== undefined && recentList?.machineImage !== null) ? "data:image/jpeg;base64," + recentList?.machineImage : MachineImage} />
                                                <div>
                                                    <span>{recentList?.machineName}</span>
                                                    <div>{typeof (recentList?.machineId) !== "number" ? recentList?.machineId : ""}</div>
                                                </div>
                                            </div>    
                                        </div>
                                    })
                                }
                            </div>
                        </>
                        :
                        null
                }

                <div>
                    {
                        this.state.machineGroupPopUp ?
                            <LiReactDialog
                                enableStoring="delivery_trackingsortTruck"
                                title={<LiMultiterm textId="99032170" textVal="Choose Line/Machine ..." />}
                                actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                                onClose={this.closePopUp.bind(this)}
                                hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                                width={900} height={600} buttons={
                                    [
                                        {
                                            text: <LiMultiterm textId="99000240" textVal="Close" />,
                                            onClick: () => this.closePopUp(),
                                            className: "LiGeneral_raisedButton"
                                        }
                                    ]
                                }
                            >
                                {LoadMachineList}
                            </LiReactDialog>
                            : ""


                    }
                    {
                        this.props.isMobile ? LoadMachineList : null
                    }
                    {
                    this.state.userSettingsFailed ?
                        <LiReactDialog
                            enableStoring="warning"
                            title={<LiMultiterm textId="1002208" textVal="Error" />}
                            actionsContainerStyle={{ backgroundColor: '#ffffff', padding: '8px' }}
                            onClose={()=> this.setState({userSettingsFailed : false})}
                            hasCloseIcon={true} isResizable={true} isDraggable={true} modal={true}
                            width={500} height={200} buttons={
                                [
                                    {
                                        text: <LiMultiterm textId="99030394" textVal="Ok" />,
                                        onClick: ()=> this.setState({userSettingsFailed : false}),
                                        className: "LiGeneral_raisedButton"
                                    }
                                ]
                            }
                        >
                            <div className='PleaseSelectAMachineMessage'>
                                <LiMultiterm textId="6002133" textVal="No authorization !" />
                            </div>
                        </LiReactDialog>
                        : ""
                }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSelectedMachine: FilterActions.setSelectedMachine,
        setAddNewMachineLine: FilterActions.setAddNewMachineLine,
        setMachineGroupList: FilterActions.setMachineGroupList,
        setMachineList: FilterActions.setMachineList,
        setRecentList: FilterActions.setRecentList,
        setUserSettingsStatus: FilterActions.setUserSettingsStatus
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(MachineSelector);
