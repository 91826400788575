import React, { useEffect, useLayoutEffect, useRef, useState, useMemo } from "react";
import { DashBoardWidget } from "dashboard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GridLayout, { Responsive, WidthProvider } from "react-grid-layout";
import { LiBreadCrumbs } from "liseccomponents";
import * as conversionLib from "lisecutilityfunctions/lib/commonfunction";
import breadcrumbsimg from "./images/dashboard.svg";
import {
  initialState,
  OEEWidgetProp,
  QualityWidgetProp,
  PartsTotalWidgetProp,
  ProductionAmountPerHourWidgetProp,
  DowntimeReasonsDurationWidgetProp,
  DowntimeReasonsCountWidgetProp,
  PerformanceWidgetProp,
  AvailabilityWidgetProp,
  DownTimeWidgetProp,
  PartsOverTimeWidgetProp,
} from "./utility/const";
import {
  OpenApiClient_analytics_v1,
  getSiteName,
} from "lisecutilityfunctions/lib/openapi";
import { setSelectedDate, setOverviewData } from "./actions/filteractions";
import {
  LiMultiterm,
  getMultitermTexts,
} from "lisecutilityfunctions/lib/utilitylib";
import MachineImage from "./images/machineimage.svg";
import {
  convertToFav,
  mapEnumUNIT,
  Translate
} from "lisecutilityfunctions/lib/utilitylib";
import {
  LogLevel,
  HttpTransportType,
  HubConnectionBuilder,
} from "@microsoft/signalr";
// import MachineImage from './machinesandlines/images/machinePicture.jpg';
import * as FilterActions from './actions/filteractions'
import "./css/oeedashboard.css";
import "./filtercomponents/filter.css";



import Filterpanel from "./filters/filterpanel";
import FilterpanelXS from "./filters/filterpanelsmalldevice";
import useWindowSize from "./hooks/useWindowSize";
const ResponsiveGridLayout = WidthProvider(Responsive);
let oeeDashboardInterval = null
const timeoutVariable = 300000

const OEEDashBoardLayout = (props) => {
  const oeedashboardObj = {
    OEEDashBoard: {
      label: "OEE Dashboard",
      path: "#",
    },
  };

  const size = useWindowSize();

  const siteName = getSiteName();

  const themeName =
    conversionLib.getLocalStorageValue("core.app.theme") !== null
      ? conversionLib.getLocalStorageValue("core.app.theme")
      : "LightTheme";

  const [qualityOutput, setQualityOutput] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [partsCount, setPartsCount] = useState(null);
  const [partsOverTime, setPartsOverTime] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [downTime, setDownTime] = useState(null);
  const [oeeData, setOeeData] = useState(null);
  const [productionAmount, setproductionAmount] = useState(null);
  const [downtimeReasonsDuration, setdowntimeReasonsDuration] = useState(null);
  const [downtimeReasonsCount, setdowntimeReasonsCount] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [isMachine, setIsMachine] = useState(true);
  const [part, setPart] = useState(true);
  const [area, setArea] = useState(false);
  const [weight, setWeight] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState([]);
  const [displayType, setDisplayType] = useState();
  const [alarmData, setAlarmData] = useState({});
  const [alarmText, setAlarmText] = useState('');
  const [selectDate, setSelectDate] = useState(sessionStorage.getItem('selectedDate') ? sessionStorage.getItem('selectedDate') : new Date())
  const [shiftTarget, setShiftTarget] = useState()
  const currentLanguage = localStorage.getItem('core.app.language.id3') ? localStorage.getItem('core.app.language.id3') : 'eng'

  useEffect(() => {
    function outputsize() {
      _fireResizeManually();
    }
    new ResizeObserver(outputsize).observe(
      document.getElementById("OEEDashboard_Layout_Div")
    );
    getTranslations();
  }, []);

  useEffect(() => {
    getDisplayTypeFromSession();
  }, [
    props.filterStore.selectedMachine,
    props.filterStore.selectedDate,
    props.filterStore.selectedShifts,
  ]);

  useEffect(() => {
    if (
      props.filterStore.selectedMachine &&
      props.filterStore.selectedDate &&
      displayType
    ) {
      machineOverviewCalls();
    }
  }, [
    props.filterStore.selectedMachine,
    props.filterStore.selectedDate,
    props.filterStore.selectedShifts,
    displayType,
  ]);

  let getDisplayTypeFromSession = () => {
    let displayTypeArray = ["Pieces", "Area", "Weight"];
    let displayTypeFromSession = sessionStorage.getItem("displayType");
    if (displayTypeArray.includes(displayTypeFromSession)) {
      setDisplayType(displayTypeFromSession);
    } else {
      setDisplayType("Pieces");
    }
  };

  useEffect(() => {
    if (displayType === "Area") {
      setArea(true);
      setPart(false);
      setWeight(false);
    } else if (displayType === "Weight") {
      setWeight(true);
      setArea(false);
      setPart(false);
    } else {
      setPart(true);
      setArea(false);
      setWeight(false);
    }
    sessionStorage.setItem("displayType", displayType);
    selectedTypeChangeReset();
  }, [displayType]);

 
  useEffect(() => {
    if (
      props.filterStore.selectedMachine &&
      isNaN(props.filterStore.selectedMachine)
    ) {
      let connection = new HubConnectionBuilder()
        .withUrl(
          `${window.location.origin}/api/io_t_event_data_collector/liveAlarmHub`,
          {
            // skipNegotiation: true,
            transport: HttpTransportType.ServerSentEvents,
            accessTokenFactory: () =>
              localStorage.getItem("config.accessToken.null"),
          }
        )
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      connection.keepAliveIntervalInMilliseconds = timeoutVariable;
      connection.serverTimeoutInMilliseconds = timeoutVariable;

      connection.start()
      .then(async (data) => {{       
         await connection.invoke("SubscribeToTopic",props.filterStore.selectedMachine);       
         connection.on("ReceiveLiveAlarmSummary", async (data) => {      
         const parsedData = JSON.parse(data);
         const alarmMultitermFormat = `text.${currentLanguage}.${parsedData?.ALA_ERR_TXTNR}`
         if(localStorage.getItem(alarmMultitermFormat)){
          setAlarmText(localStorage.getItem(alarmMultitermFormat))
         }else{
          const multitermData = await getMultitermTexts([parsedData?.ALA_ERR_TXTNR]);
          const convertedMultitermValue = conversionLib.convertToJSON(multitermData.state.response.data);
          for (let i = 0; i < convertedMultitermValue.length; i++) {
            localStorage.setItem(`text.${currentLanguage}.${convertedMultitermValue[i].number}`, convertedMultitermValue[i].text)
          }
          setAlarmText(convertedMultitermValue[0]?.text)
         }
         setAlarmData(parsedData);
         }); 
         connection.onreconnecting(() => {
            // console.log("reconnecting");
         });
        
         connection.onreconnected(async () => {
            await connection.invoke("SubscribeToTopic", props.filterStore.selectedMachine);
         });        
        }})
        .catch((err) => {
          
        })

      /**Closing the connection on unmounting */
      return async () => {
        await connection.invoke(
          "UnsubscribeFromTopic",
          props.filterStore.selectedMachine
        );
        await connection.stop();
        setAlarmData({});
        setAlarmText('')
      };
    }
    else if(isNaN(props.filterStore.selectedMachine) === false){
      setAlarmData({})
      setAlarmText('')
    }
  }, [props.filterStore.selectedMachine]);

  const returnAlarmClass = (value) => {
    if (value === 1) {
      return "information_alarm";
    } else if (value >= 2 && value <= 4) {
      return "warning_alarm";
    } else if (value >= 6 && value <= 8) {
      return "error_alarm";
    } else if (value >= 15) {
      return "fatal_alarm";
    } else {
      return "";
    }
  };

  let callbackMachineDetails = (responseData) => {
    if (responseData.state.response.status === 200) {
      let jsonData = conversionLib.convertToJSON(
        responseData.state.response.data
      );
      setOverviewData(jsonData);
      if (jsonData.hasOwnProperty("machineId") === true) {
        window.history.pushState(
          "",
          "",
          `${window.location.origin}${window.location.pathname}?machineId=${jsonData.machineId}`
        );
        props.setOverviewData(jsonData);
      }
    }
    props.loader.showLoader({ GET_Machines: false });
  };

  let getTranslations = async () => {
    let textsObj = {};
    let textArray = [
      99032531, 6005948, 99016959, 99005018, 99032532, 99031622, 99032533,
      99032534, 99031619, 99031621, 99032535, 99032536,
    ];
    var tempArrayofText = await getMultitermTexts(textArray);
    let texts = conversionLib.convertToJSON(
      tempArrayofText.state.response.data
    );
    for (let i = 0; i < texts.length; i = i + 1) {
      if (textsObj && texts && texts[i]) {
        textsObj[texts[i].number] = texts[i].text;
      }
    }
    setTranslatedTexts(textsObj);
  };

  let machineOverviewCalls = () => {
    props.loader.showLoader({ GET_Machines: true });
    if (displayType) {
      if (isNaN(parseInt(props.filterStore.selectedMachine))) {
        OpenApiClient_analytics_v1.getClient(
          siteName
        ).GET_MachineOverview_machine_siteId_machineId(
          callbackMachineDetails,
          "1",
          props.filterStore.selectedMachine,
          props.filterStore.selectedDate,
          props.filterStore.selectedShifts.length > 0
            ? props.filterStore.selectedShifts
            : null,
          displayType
        );
        setIsMachine(true);
      } else {
        OpenApiClient_analytics_v1.getClient(
          siteName
        ).GET_MachineOverview_machine_siteId_machineGroupId_MachineGroupOverview(
          callbackMachineDetails,
          "1",
          props.filterStore.selectedMachine,
          props.filterStore.selectedDate,
          props.filterStore.selectedShifts.length > 0
            ? props.filterStore.selectedShifts
            : null,
          displayType
        );
        setIsMachine(false);
      }
    }
  };

  /**
   * Fire resize event in order to repaint dashboard.
   * @private
   */
  function _fireResizeManually() {
    setTimeout(() => {
      const evt = document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }, 301); // wait just 1 millisecond more than transition open/close dashboard notes animations
  }

  /**
   * @description rerender the grid layout when change in Machine
   */
  // useEffect(() => {
  //     if (props.filterStore.selectedMachine) {
  //         autoRefreshDashboard()
  //         resetOeeDashboard()
  //     }
  // }, [props.filterStore.selectedMachine])

  /**
   * @description rerender the grid layout when change in shift or date
   */
  useEffect(() => {
    if (props.filterStore.selectedMachine) {
      autoRefreshDashboard();
      resetOeeDashboard();
    }

    return () => {
      clearInterval(oeeDashboardInterval);
    };
  }, [props.filterStore.selectedShifts]);

  /**
   * @description rerender the grid layout when change in Machine
   */
  useEffect(() => {
    if (props.filterStore.autoRefresh) {
      autoRefreshDashboard();
    }

    return () => {
      clearInterval(oeeDashboardInterval);
    };
  }, [props.filterStore.autoRefresh]);

  useEffect(() => {
    let dataSetting = settingsProperties();
    setAngularGauges(dataSetting);
  }, [window.innerWidth]);

  const autoRefreshDashboard = () => {
    clearInterval(oeeDashboardInterval);
    oeeDashboardInterval = setInterval(() => {
      resetOeeDashboard();
    }, props.filterStore.autoRefresh * 60 * 1000);
  };

  /**
   * @description This function is used to reset the OEE Dashboard state to rerender the widgets when machine or shift is changed
   */
  function resetOeeDashboard() {
    setQualityOutput(null);
    setPerformance(null);
    setPartsCount(null);
    setPartsOverTime(null);
    setAvailability(null);
    setDownTime(null);
    setOeeData(null);
    setproductionAmount(null);
    setdowntimeReasonsDuration(null);
    setdowntimeReasonsCount(null);
    setTimeout(() => {
      getDisplayTypeFromSession();
      machineOverviewCalls();
      setOeeWidget();
    }, 200);
  }

  function selectedTypeChangeReset() {
    setPartsCount(null);
    setPartsOverTime(null);
    setproductionAmount(null);
    setTimeout(() => {
      machineOverviewCalls();
      setOeeWidget();
    }, 200);
  }

  useEffect(() => {
    setPerformance(null);
    setQualityOutput(null);
    setOeeData(null);
    setTimeout(() => {
      setOeeWidget();
    }, 1000);
  }, []);

  // let displayType = "Pieces";

  let handleRadio = (type) => {
    // setOverviewData(null);
    if (type === "Part") {
      // setPart(true)
      // setArea(false)
      // setWeight(false)
      setDisplayType("Pieces");
    } else if (type === "Area") {
      // setPart(false)
      // setArea(true)
      // setWeight(false)
      setDisplayType("Area");
    } else if (type === "Weight") {
      // setPart(false)
      // setArea(false)
      // setWeight(true)
      setDisplayType("Weight");
    }
    setShiftTarget(null)
  };

  /**
   * @description This function is used to find the radius.
   * @description Gets the width of the widget and divide by 2 to get the radius
   * @description Gets the center coordintaes of the widget
   * @returns {radius: number, centerX: number, centerY: number}
   */

  function getOuterRadius(id) {
    let radius = null;
    let centerX = null;
    let centerY = null;
    if (document.getElementById(id)) {
      let elem = document.getElementById(id).getBoundingClientRect();
      let width = elem.width / 2 - 10; // 20- padding and margin into consideration
      let height = (elem.height - 25) / 2 - 20; // 20- padding and margin into consideration
      radius = width < height ? width : height; // 30- padding and margin into consideration
      centerX = document.getElementById(id).offsetWidth / 2 - 8;
      centerY = (document.getElementById(id).offsetHeight - 30) / 2 - 8;
    }
    return { radius, centerX, centerY };
  }

  /**
   * @description This function is used to set the setting properties of the widget.
   * @returns {dataSetting: Object}
   */

  function settingsProperties() {
    let dataSetting = {};
    let shiftSetting = "";
    if (props.filterStore.selectedShifts.length > 0) {
      shiftSetting = props.filterStore.selectedShifts;
    }
    if (isNaN(parseInt(props.filterStore.selectedMachine))) {
      dataSetting = {
        Date: props.filterStore.selectedDate,
        Machine: [props.filterStore.selectedMachine],
        Shift: shiftSetting,
        Mobile: size.width < 600 ? true : false,
      };
    } else {
      dataSetting = {
        Date: props.filterStore.selectedDate,
        MachineGroupId: [props.filterStore.selectedMachine],
        Shift: shiftSetting,
        Mobile: size.width < 600 ? true : false,
      };
    }
    return dataSetting;
  }

  /**
   * @description This function is used to redraw the gauges that uses angular gauge as chart
   * @returns {void}
   */

  function setAngularGauges(dataSetting) {
    setOeeData((oeeData) => {
      let tempOEEWidgetProp = OEEWidgetProp;
      tempOEEWidgetProp.name["text"] =
        translatedTexts["99032531"] === undefined
          ? "OEE"
          : translatedTexts["99032531"];
      oeeData = tempOEEWidgetProp;

      let { radius, centerX, centerY } = getOuterRadius("2_widget");
      // let centerX = getCenterX() ? getCenterX() : oeeData.generalSettings.valueX
      // let outerRadius = getOuterRadius('2_widget') ? getOuterRadius('2_widget') : oeeData.generalSettings.gaugeouterradius

      // oeeData.generalSettings.valueX = centerX
      // oeeData.generalSettings.valueY = centerY
      oeeData.generalSettings.doughnutradius = radius - 12;
      oeeData.generalSettings.pieradius = radius;
      oeeData.dataSettingsobject = dataSetting;
      return oeeData;
    });

    setQualityOutput((qualityOutput) => {
      let tempQualityWidgetProp = QualityWidgetProp;
      tempQualityWidgetProp.name["text"] =
        translatedTexts["99016959"] === undefined
          ? "Quality"
          : translatedTexts["99016959"];
      qualityOutput = tempQualityWidgetProp;
      let { radius, centerX, centerY } = getOuterRadius("4_widget");
      // let centerX = getCenterX() ? getCenterX() : oeeData.generalSettings.valueX
      // let outerRadius = getOuterRadius('2_widget') ? getOuterRadius('2_widget') : oeeData.generalSettings.doughnutradius

      // qualityOutput.generalSettings.valueX = centerX
      // qualityOutput.generalSettings.valueY = centerY
      qualityOutput.generalSettings.doughnutradius = radius - 12;
      qualityOutput.generalSettings.pieradius = radius;
      qualityOutput.dataSettingsobject = dataSetting;
      return qualityOutput;
    });

    setPerformance((performance) => {
      let tempPerformanceWidgetProp = PerformanceWidgetProp;
      tempPerformanceWidgetProp.name["text"] =
        translatedTexts["6005948"] === undefined
          ? "Performance"
          : translatedTexts["6005948"];
      performance = PerformanceWidgetProp;
      let { radius, centerX, centerY } = getOuterRadius("3_widget");
      // let centerX = getCenterX() ? getCenterX() : oeeData.generalSettings.valueX
      // let outerRadius = getOuterRadius('2_widget') ? getOuterRadius('2_widget') : oeeData.generalSettings.doughnutradius

      // performance.generalSettings.valueX = centerX
      // performance.generalSettings.valueY = centerY
      performance.generalSettings.doughnutradius = radius - 12;
      performance.generalSettings.pieradius = radius;
      performance.dataSettingsobject = dataSetting;
      return performance;
    });

    // setPartsCount((partsCount) => {
    //     let tempPartsTotalWidgetProp = PartsTotalWidgetProp;
    //     tempPartsTotalWidgetProp.name["text"] = displayType === "Pieces" ? translatedTexts["99032534"] === undefined ? "Quantity Produced" : translatedTexts["99032534"] : displayType === "Area" ? translatedTexts["99031619"] === undefined ? "Produced Area" : translatedTexts["99031619"] + " [sqm]" : displayType === "Weight" ? translatedTexts["99031621"] === undefined ? "Produced Weight" : translatedTexts["99031621"] + " [kg]" : "Parts Count"
    //     partsCount = tempPartsTotalWidgetProp
    //     let { radius, centerX, centerY } = getOuterRadius('5_widget')
    //     // let centerX = getCenterX() ? getCenterX() : oeeData.generalSettings.valueX
    //     // let outerRadius = getOuterRadius('2_widget') ? getOuterRadius('2_widget') : oeeData.generalSettings.doughnutradius

    //     // partsCount.generalSettings.valueX = centerX
    //     // partsCount.generalSettings.valueY = centerY
    //     partsCount.generalSettings.doughnutradius = radius - 12
    //     partsCount.generalSettings.pieradius = radius
    //     dataSetting = {
    //         ...dataSetting, ...{
    //             "DisplayType": displayType,
    //             "LineType": "All",
    //             "LineNo": "-1"
    //         }
    //     }
    //     partsCount.dataSettingsobject = dataSetting
    //     return partsCount
    // })
  }

  function calculateUnit(value) {
    let dataSetting = settingsProperties();
    let tmp = value;
    let tempPartsOverTimeWidgetProp = PartsOverTimeWidgetProp;
    let unit = overviewData?.shiftTargetUnit
      ? ` [${mapEnumUNIT[overviewData?.shiftTargetUnit]}]`
      : "";
    tempPartsOverTimeWidgetProp.name["text"] =
      translatedTexts["99032535"] === undefined
        ? "Production Per Day"
        : translatedTexts["99032535"] + unit;
    tmp = tempPartsOverTimeWidgetProp;
    dataSetting = {
      ...dataSetting,
      ...{
        DisplayType: displayType,
        LineType: "All",
        LineNo: "-1",
        TimePeriod: "Weekly",
        // "showvalues": "1",
      },
    };
    tmp.dataSettingsobject = dataSetting;
    tmp.generalSettings.refreshTime = 2;
    return tmp;
  }

  function calculateUnitForPartsCount(value) {
    let dataSetting = settingsProperties();
    let temp = value;
    let tempPartsTotalWidgetProp = PartsTotalWidgetProp;
    tempPartsTotalWidgetProp.name["text"] =
      displayType === "Pieces"
        ? translatedTexts["99032534"] === undefined
          ? "Quantity Produced"
          : translatedTexts["99032534"]
        : displayType === "Area"
        ? translatedTexts["99031619"] === undefined
          ? "Produced Area"
          : translatedTexts["99031619"] +
            ` [${mapEnumUNIT[overviewData?.shiftTargetUnit]}]`
        : displayType === "Weight"
        ? translatedTexts["99031621"] === undefined
          ? "Produced Weight"
          : translatedTexts["99031621"] +
            ` [${mapEnumUNIT[overviewData?.shiftTargetUnit]}]`
        : "Parts Count";
    temp = tempPartsTotalWidgetProp;
    let { radius, centerX, centerY } = getOuterRadius("5_widget");
    // let centerX = getCenterX() ? getCenterX() : oeeData.generalSettings.valueX
    // let outerRadius = getOuterRadius('2_widget') ? getOuterRadius('2_widget') : oeeData.generalSettings.doughnutradius

    // temp.generalSettings.valueX = centerX
    // temp.generalSettings.valueY = centerY
    temp.generalSettings.doughnutradius = radius - 12;
    temp.generalSettings.pieradius = radius;
    dataSetting = {
      ...dataSetting,
      ...{
        DisplayType: displayType,
        LineType: "All",
        LineNo: "-1",
      },
    };
    temp.dataSettingsobject = dataSetting;
    return temp;
  }

  function calculateUnitForProductionAmount(value) {
    let dataSetting = settingsProperties();
    let temp = value;
    let tempProductionAmountPerHourWidgetProp =
      ProductionAmountPerHourWidgetProp;
    let unit = overviewData?.shiftTargetUnit
      ? ` [${mapEnumUNIT[overviewData?.shiftTargetUnit]}]`
      : "";
    tempProductionAmountPerHourWidgetProp.name["text"] =
      translatedTexts["99032536"] === undefined
        ? "Production Per Hour"
        : translatedTexts["99032536"] + unit;
    temp = tempProductionAmountPerHourWidgetProp;
    dataSetting = {
      ...dataSetting,
      ...{
        DisplayType: displayType,
        TimePeriod: "Weekly",
        LineType: "All",
      },
    };
    temp.dataSettingsobject = dataSetting;
    temp.generalSettings.refreshTime = 2;

    return temp;
  }

  useEffect(() => {
    if (!overviewData) return;
    let value = calculateUnit(partsOverTime);
    let valueForProductionAmount =
      calculateUnitForProductionAmount(productionAmount);
    setPartsOverTime(value);
    setproductionAmount(valueForProductionAmount);
    returnAttiburte();
  }, [overviewData]);


  useEffect(() => {
    if (!overviewData) return;
    let valueForPartsCount = calculateUnitForPartsCount(partsCount);
    setPartsCount(valueForPartsCount);
  }, [overviewData, window.innerWidth]);

  /**
   * @description set the OEE configuration for all widgets
   */
  function setOeeWidget() {
    let dataSetting = settingsProperties();

    setAngularGauges(dataSetting);

    // setPartsOverTime((partsOverTime) => {
    //     calculateUnit(partsOverTime)
    // })

    setAvailability((availability) => {
      availability = AvailabilityWidgetProp;
      availability.dataSettingsobject = dataSetting;
      availability.generalSettings.refreshTime = 2;

      return availability;
    });

    setDownTime((downTime) => {
      downTime = DownTimeWidgetProp;
      downTime.dataSettingsobject = dataSetting;
      downTime.generalSettings.refreshTime = 2;

      return downTime;
    });

    // setproductionAmount((productionAmount) => {
    //     let tempProductionAmountPerHourWidgetProp = ProductionAmountPerHourWidgetProp;
    //     let unit = overviewData?.shiftTargetUnit ? ` [${mapEnumUNIT[overviewData?.shiftTargetUnit]}]` : "";
    //     tempProductionAmountPerHourWidgetProp.name["text"] = translatedTexts["99032536"] === undefined ? "Production Per Hour" : translatedTexts["99032536"]
    //     productionAmount = tempProductionAmountPerHourWidgetProp
    //     dataSetting = {
    //         ...dataSetting, ...{
    //             "DisplayType": displayType,
    //             "TimePeriod": "Weekly",
    //             "LineType": "All"

    //         }
    //     }
    //     productionAmount.dataSettingsobject = dataSetting
    //     productionAmount.generalSettings.refreshTime = 2

    //     return productionAmount
    // })

    setdowntimeReasonsDuration((downtimeReasonsDuration) => {
      let tempDowntimeReasonsDurationWidgetProp =
        DowntimeReasonsDurationWidgetProp;
      tempDowntimeReasonsDurationWidgetProp.name["text"] =
        translatedTexts["99031622"] === undefined
          ? "Alarm Duration"
          : translatedTexts["99031622"];
      downtimeReasonsDuration = tempDowntimeReasonsDurationWidgetProp;
      dataSetting = {
        ...dataSetting,
        ...{
          DisplayType: displayType,
          LineType: "All",
          LineNo: "-1",
        },
      };
      downtimeReasonsDuration.dataSettingsobject = dataSetting;
      downtimeReasonsDuration.generalSettings.refreshTime = 2;

      return downtimeReasonsDuration;
    });

    setdowntimeReasonsCount((downtimeReasonsCount) => {
      let tempDowntimeReasonsCountWidgetProp = DowntimeReasonsCountWidgetProp;
      tempDowntimeReasonsCountWidgetProp.name["text"] =
        translatedTexts["99032533"] === undefined
          ? "Alarm Count"
          : translatedTexts["99032533"];
      downtimeReasonsCount = tempDowntimeReasonsCountWidgetProp;
      dataSetting = {
        ...dataSetting,
        ...{
          DisplayType: displayType,
          LineType: "All",
          LineNo: "-1",
        },
      };
      downtimeReasonsCount.dataSettingsobject = dataSetting;
      downtimeReasonsCount.generalSettings.refreshTime = 2;

      return downtimeReasonsCount;
    });
  }

  /**
   * @description This function is used to set the selected machine
   */
  const selectedDate = (value) => {
    props.setSelectedDate(value);
  };

  const onResize = (event) => {
    // console.log(event, "event");
    // forceUpdate();
    // setOeeWidget()
  };

  const layoutChange = (currLayout, alllayouts) => {
    // console.log(currLayout, alllayouts, "currLayout, alllayouts");
  };

  const onWidthChange = (containerWidth, margin, cols, containerPadding) => {
    // console.log(center);

    if (props.filterStore.selectedMachine) {
      // console.log(containerWidth, margin, cols, containerPadding, "on width change");
      // myInterval && clearInterval(myInterval);
      // myInterval = setInterval(() => {
      //     let dataSetting = settingsProperties()
      //     setAngularGauges(dataSetting)
      // }, 2000);
    }
  };

  const timeFormating = () => {
    if (
      overviewData.avgCycleTime !== undefined &&
      overviewData.avgCycleTime > 60
    ) {
      let years = 0;
      let months = 0;
      let days = 0;
      let totalSeconds = overviewData.avgCycleTime;
      let hours = Math.floor(totalSeconds / 3600);
      if (hours > 24) {
        days = Math.floor(hours / 24);
        hours = hours % 24;
      }
      if (days > 30) {
        months = Math.floor(days / 30);
        days = days % 30;
      }
      if (months > 12) {
        years = Math.floor(months / 12);
        months = months % 12;
      }
      totalSeconds %= 3600;
      let minutes = Math.floor(totalSeconds / 60);
      let seconds = totalSeconds % 60;
      years = String(years).padStart(2, "0");
      months = String(months).padStart(2, "0");
      days = String(days).padStart(2, "0");
      minutes = String(minutes).padStart(2, "0");
      hours = String(hours).padStart(2, "0");
      seconds = String(seconds).padStart(2, "0");
      if (parseInt(years) > 0) {
        return (
          years +
          "y " +
          months +
          "m " +
          days +
          "d " +
          hours +
          "h " +
          minutes +
          "m " +
          Math.round(seconds) +
          "s"
        );
      } else if (parseInt(months) > 0) {
        return (
          months +
          "m " +
          days +
          "d " +
          hours +
          "h " +
          minutes +
          "m " +
          Math.round(seconds) +
          "s"
        );
      } else if (parseInt(days) > 0) {
        return (
          days +
          "d " +
          hours +
          "h " +
          minutes +
          "m " +
          Math.round(seconds) +
          "s"
        );
      } else if (parseInt(hours) > 0) {
        return hours + "h " + minutes + "m " + Math.round(seconds) + "s";
      } else if (parseInt(minutes) > 0) {
        return minutes + "m " + Math.round(seconds) + "s";
      }
    } else {
      return Math.round(overviewData.avgCycleTime) + "s";
    }
  };

  let returnAttiburte = () => {
    if (area || weight) {
      const unitFavouriteIndex = JSON.parse(localStorage.getItem(`config.userSettings.${localStorage.getItem('user.id')}`)) ? JSON.parse(localStorage.getItem(`config.userSettings.${localStorage.getItem('user.id')}`))?.filter(item => item.keyName ===  `core.app.attribute.${displayType.toLowerCase()}.favourite`)[0]?.value : 0;
      const areaThresholdAttribute = overviewData?.areaThresholdAttribute ? overviewData?.areaThresholdAttribute : overviewData?.shiftTargetUnit;
      const weightThresholdAttribute = overviewData?.weightThresholdAttribute ? overviewData?.weightThresholdAttribute:  overviewData?.shiftTargetUnit;
      if (overviewData?.shiftTargetUnit !== undefined) {
        var attribute = convertToFav({
          attrDecimal: 2,
          attrFavIndex: unitFavouriteIndex,
          attrList: [],
          attrMapAttrId: [],
          attrType: area ? "Area" : weight ? "Weight" : "",
          attrValue: overviewData.shiftTarget,
          attrUnitName: area
            ? mapEnumUNIT[areaThresholdAttribute]
            : weight
            ? mapEnumUNIT[weightThresholdAttribute]
            : "",
        }).attrValue;
        let requiredValue;
        let d = [...(attribute.split('.')[1]?.split(' ')[0])]
        if(d && d.every(item => item === '0')){
          requiredValue = `${attribute.split('.')[0]} ${attribute.split(' ')[1]}`  ;
          setShiftTarget(requiredValue)
        }else {
          requiredValue = attribute;
          setShiftTarget(requiredValue)
        }
        setShiftTarget(requiredValue);
      }
    } else if (part) {
      setShiftTarget(overviewData?.shiftTarget);
    } else {
      setShiftTarget("--");
    }
  };


  return (
    <Translate>
    <div className="OEEDashboard_mainContainer">
      <div className={`OEE_Breadcrumbs ${props.hide ? "BreadCrumbHeaderHide" : ""}`}>
        <LiBreadCrumbs breadcrumbsdataObj={oeedashboardObj} />
      </div>
        <div className="OEEDashboard_Layout_With_Filter">
      <div className="OEEDashboard_layoutSidebarContainer">
          <FilterpanelXS loader={props.loader} setHide={props.setHide} selectDate={selectDate} setSelectDate={setSelectDate} />
        {props.filterStore.status ? "" :
        <div id="OEEDashboard_Layout_Div" className="OEEDashboard_Layout_Div">
          <ResponsiveGridLayout
            className="layout"
            layouts={initialState}
            onLayoutChange={layoutChange}
            onResize={onResize}
            breakpoints={{ lg: 1800, md: 1024, sm: 500, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 3, xs: 3, xxs: 3 }}
            onWidthChange={onWidthChange}
            // rowHeight={85}
            rowHeight={75}
            margin={[10, 10]}
            compactType="horizontal"
            verticalCompact="true"
            isDraggable={false}
            isResizable={false}
            useCSSTransforms={true}
          >
            {/* {layouts}
             */}

            <div
              id={"1_widget"}
              className={
                themeName +
                " oee_dashboard_widgets oee_dashboard_graphical_container"
              }
              key="1"
            >
              <div className={themeName + " oeeDashboardMachineImageCont"}>
                <div className={"oeeDashboardImag"}>
                  <span className={"oeeDashboardMachineName"}>
                    {overviewData?.machineName ||
                      overviewData?.machineGroupName}
                  </span>
                   
                    <img
                      src={
                        overviewData &&
                        overviewData?.machineImage !== undefined &&
                        overviewData?.machineImage !== null &&
                        overviewData?.machineImage !== ""
                          ? "data:image/jpeg;base64," +
                            overviewData?.machineImage
                          : MachineImage
                      }
                      alt="machineImage"
                      className={themeName + " oeeDashboardMachineImage"}
                    />
                  
                </div>
                <div className={"oeeDashboardMachineDetails"}>
                  {isMachine ? (
                    <div>
                      <span className="Label">
                        <LiMultiterm textId="99002931" textVal="Model" /> :
                      </span>
                      <span>
                        {overviewData?.machineModel !== undefined
                          ? overviewData.machineModel
                          : "--"}
                      </span>
                    </div>
                  ) : null}
                  <div>
                    <span className="Label">
                      <LiMultiterm textId="99019533" textVal="State" /> :
                    </span>
                    <span className="Status">
                      {overviewData?.status !== undefined ? (
                        <LiMultiterm
                          textId={String(overviewData?.statusTextNo)}
                          textVal={overviewData.status}
                        />
                      ) : (
                        "--"
                      )}
                    </span>
                  </div>
                  {isMachine ? (
                    <div>
                      <span className="Label">
                        <LiMultiterm
                          textId="99011841"
                          textVal="AVG Cycle Time"
                        />{" "}
                        :
                      </span>
                      <span>
                        {overviewData?.avgCycleTime !== undefined
                          ? timeFormating()
                          : "--"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {isMachine ? <div>
                                            <span className='Label'>AVG Cycle Time :</span><span>{overviewData?.avgCycleTime !== undefined ? overviewData.avgCycleTime.toFixed(2) + "s" : "--"}</span>
                                        </div> : ""} */}

                  <div>
                    <span className="Label">
                      {props?.filterStore?.selectedShifts?.length > 0 ? <LiMultiterm textId="99032493" textVal="Shift Target" /> :  <LiMultiterm textId="99034060" textVal="Daily target" />} :
                    </span>
                    <span>
                      {shiftTarget === null || shiftTarget === undefined ? '--' : shiftTarget}        
                    </span>
                  </div>
                  <div>
                    <span className="Label">
                      <LiMultiterm textId="99032578" textVal="Productivity" /> :
                    </span>
                    <span>
                      <form>
                        <input
                          checked={part}
                          type="radio"
                          id="parts"
                          name="unit"
                          onClick={() => handleRadio("Part")}
                        />
                        <label for="parts">
                          <LiMultiterm textId="06003843" textVal="Pieces" />
                        </label>
                        <br />
                        <input
                          checked={area}
                          type="radio"
                          id="area"
                          name="unit"
                          onClick={() => handleRadio("Area")}
                        />
                        <label for="area">
                          <LiMultiterm textId="06003956" textVal="Area" />
                        </label>
                        <br />
                        <input
                          checked={weight}
                          type="radio"
                          id="weight"
                          name="unit"
                          onClick={() => handleRadio("Weight")}
                        />
                        <label for="weight">
                          <LiMultiterm textId="06003997" textVal="Weight" />
                        </label>
                      </form>
                    </span>
                  </div>
                  {/* <table>
                                            <tr>
                                                <td>Model :</td>
                                                <td>{overviewData?.machineModel !== undefined ? overviewData.machineModel : "--"}</td>
                                            </tr>
                                            <tr>
                                                <td>State :</td>
                                                <td>{overviewData?.status !== undefined ? <LiMultiterm textId={String(overviewData?.statusTextNo)} textVal={overviewData.status} /> : "--"}</td>
                                            </tr>
                                            <tr>
                                                <td>AVG Cycle Time :</td>
                                                <td>{overviewData?.avgCycleTime !== undefined ? overviewData.avgCycleTime : "--"}</td>
                                            </tr>
                                            <tr>
                                                <td>Shift Target :</td>
                                                <td>{overviewData?.shiftTarget !== undefined ? overviewData.shiftTarget : "--"}</td>
                                            </tr>
                                        </table> */}
                  {alarmData && alarmData?.ALA_CLASSNR !== 0 && (
                    <div className={`alarm ${returnAlarmClass(alarmData?.ALA_CLASSNR)}`}>
                      <span
                        style={{
                          padding: "5px",
                          marginBottom: "0px",
                          width: "100%",
                        }}
                      >
                        {alarmText}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div id={"2_widget"} className="oee_dashboard_widgets" key="2">
              {!oeeData ? null : (
                // <ReactFC id={2 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"angulargauge"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={oeeData} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={oeeData}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={"3_widget"} className="oee_dashboard_widgets" key="3">
              {!performance ? null : (
                // <ReactFC id={3 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"angulargauge"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={performance} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={performance}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={"4_widget"} className="oee_dashboard_widgets" key="4">
              {!qualityOutput ? null : (
                // <ReactFC id={4 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"angulargauge"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={qualityOutput} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={qualityOutput}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={"5_widget"} className="oee_dashboard_widgets" key="5">
              {!partsCount ? null : (
                // <ReactFC id={5 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"angulargauge"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={partsCount} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={partsCount}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={String(6)} className="oee_dashboard_widgets" key="6">
              {/* <DashBoardWidget manifestation={1} widgetProperties={widgetProps6} breadCrumbs={false} oeeDashboard={true} /> */}
              {!partsOverTime ? null : (
                // <ReactFC id={5 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"angulargauge"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={partsCount} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={partsOverTime}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={"widget7"} className="oee_dashboard_widgets" key="7">
              {!availability ? null : (
                // <ReactFC id={7 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"gantt"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={availability} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={availability}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={"widget8"} className="oee_dashboard_widgets" key="8">
              {!downTime ? null : (
                // <ReactFC id={8 + "_chart"} dataLoadErrorMessage={"LOAD THE CHARTS"} type={"gantt"} width={"100%"} height={"100%"} dataFormat="JSON" dataSource={downTime} />
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={downTime}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={String(9)} className="oee_dashboard_widgets" key="9">
              {!productionAmount ? null : (
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={productionAmount}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={String(10)} className="oee_dashboard_widgets" key="10">
              {!downtimeReasonsDuration ? null : (
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={downtimeReasonsDuration}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
            <div id={String(11)} className="oee_dashboard_widgets" key="11">
              {!downtimeReasonsCount ? null : (
                <DashBoardWidget
                  manifestation={1}
                  widgetProperties={downtimeReasonsCount}
                  breadCrumbs={false}
                  oeeDashboard={true}
                />
              )}
            </div>
          </ResponsiveGridLayout>
          <div className="OEEBottomSpace"></div>
        </div>
      }
        {/* <span id={"menuExpanIcon"} className={"kpiMenuBtn"} onClick={openNav}><img className={themeName + " kpiIcon"} src={themeName === "DarkTheme" ? KPIDarkIcon : KPILightIcon} /></span> */}

          </div>
          <Filterpanel selectDate={selectDate} setSelectDate={setSelectDate} loader={props.loader} setHide={props.setHide} />

        </div>
      {/* <div className='OEEDashboard_calendarDiv'>Calendar Component</div> */}
    </div>
    </Translate>
  );
};

function mapStateToProps(state) {
  return {
    filterStore: state.filterStore,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSelectedDate,
      setOverviewData,
      setShifts: FilterActions.setSelectedShifts,
      setShiftsList : FilterActions.setShiftsList
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(OEEDashBoardLayout);
