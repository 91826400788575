import React, { Component, useEffect, useState, Suspense } from 'react';
import { createStore } from 'redux';
import OEEStore from './reducers/index';
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { Provider } from 'react-redux';
import OEEDashboard from './oeedashboardlayout';
import Machinestagechanges from './machinesandlines/machinestagechanges';
import MachineAndLineManagement from './machinesandlines/machineandlinemanagement';
import AlarmCategoryManagement from './alarmmanagement/alarmcategorymanagement';
import { Switch, Route } from 'react-router-dom';
import ThemeSelector from '../themes/themeselector';

const store = createStore(OEEStore);

const OEEMainContainer = (props) => {
    const [hide, setHide] = useState(false)
    return (
        <ThemeSelector>
            <Provider store={store}>
                <Switch>
                    <Route exact path='/' render={() => { return <OEEDashboard loader={props.loader} setHide={setHide} hide={hide}  {...props}/> }} />
                    <Route exact path='/oeedashboard' render={() => { return <OEEDashboard loader={props.loader} setHide={setHide} hide={hide}  {...props}/> }} />
                    <Route exact path='/event_log' render={() => { return <Machinestagechanges loader={props.loader} setHide={setHide} hide={hide}  {...props}/> }} />
                    <Route exact path='/machine_and_line_management' render={() => { return <MachineAndLineManagement loader={props.loader} setHide={setHide} hide={hide}  {...props}/> }} />
                    <Route exact path='/alarm_categorization' render={() => { return <AlarmCategoryManagement loader={props.loader} setHide={setHide} hide={hide} {...props}/> }} />
                </Switch>
            </Provider>
        </ThemeSelector>
    );
}

export default OEEMainContainer;
