import { LiComboBox, LiComboList } from 'liseccomponents'
import React from 'react'
import { LiMultiterm } from 'lisecutilityfunctions/lib/utilitylib';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { setRefreshTime } from '../actions/filteractions'
import useWindowSize from '../hooks/useWindowSize';

function AutoRefresh(props) {

    const handleComboChange = (value) => {
        props.setRefreshTime(parseInt(value))
    }

    const isMobileN = props.isMobile ? "smalldevice" : "largeDevice"
    return (
        <div className='OEEDashboard_autorefresh'>
            {/* <span className='autorefresh_text'>Auto Refresh</span> */}
            <LiComboBox
                className="LiLayout_selectSite"
                floatingLabel={<LiMultiterm textId="99011352" textVal="Auto Refresh" />}
                defaultText="Select Interval"
                id={`OEE_AutoRefresh${isMobileN}`}
                key={`OEE_AutoRefresh${isMobileN}key`}
                value={props.filterStore.autoRefresh}
                onSelection={handleComboChange}>
                <LiComboList key={`OEE_AutoRefresh${isMobileN}5`} value={"5"}>5 min</LiComboList>
                <LiComboList key={`OEE_AutoRefresh${isMobileN}10`} value={"10"}>10 min</LiComboList>
                <LiComboList key={`OEE_AutoRefresh${isMobileN}15`} value={"15"}>15 min</LiComboList>
                <LiComboList key={`OEE_AutoRefresh${isMobileN}30`} value={"30"}>30 min</LiComboList>
                <LiComboList key={`OEE_AutoRefresh${isMobileN}60`} value={"60"}>1 h</LiComboList>
            </LiComboBox>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setRefreshTime: setRefreshTime,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AutoRefresh);