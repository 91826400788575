import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
const backgroundChartColor = conversionLib.getLocalStorageValue("core.app.theme") === "DarkTheme" ? "#1E2124" : "#D1D1D1";
export const initialState = {
    lg: [
        { i: "1", x: 0, y: 0, w: 6, h: 3.3, static: true },
        { i: "2", x: 6, y: 0, w: 2, h: 3.3, static: true },
        { i: "3", x: 8, y: 0, w: 2, h: 3.3, static: true },
        { i: "4", x: 10, y: 0, w: 2, h: 3.3, static: true },

        { i: "5", x: 0, y: 3.3, w: 2, h: 5.6, static: true },
        { i: "6", x: 2, y: 3.3, w: 4, h: 5.6, static: true },
        { i: "7", x: 6, y: 3.3, w: 6, h: 2.8, static: true },
        { i: "8", x: 6, y: 6.1, w: 6, h: 2.8, static: true },

        { i: "9", x: 0, y: 8.9, w: 6, h: 3.3, static: true },
        { i: "10", x: 6, y: 8.9, w: 3, h: 3.3, static: true },
        { i: "11", x: 9, y: 8.9, w: 3, h: 3.3, static: true }
    ],
    md: [
        { i: "1", x: 0, y: 0, w: 6, h: 3.3, static: true },
        { i: "2", x: 6, y: 0, w: 2, h: 3.3, static: true },
        { i: "3", x: 8, y: 0, w: 2, h: 3.3, static: true },
        { i: "4", x: 10, y: 0, w: 2, h: 3.3, static: true },

        { i: "5", x: 0, y: 3.3, w: 2, h: 5.6, static: true },
        { i: "6", x: 2, y: 3.3, w: 4, h: 5.6, static: true },
        { i: "7", x: 6, y: 3.3, w: 6, h: 2.8, static: true },
        { i: "8", x: 6, y: 6.1, w: 6, h: 2.8, static: true },

        { i: "9", x: 0, y: 8.9, w: 6, h: 3.3, static: true },
        { i: "10", x: 6, y: 8.9, w: 3, h: 3.3, static: true },
        { i: "11", x: 9, y: 8.9, w: 3, h: 3.3, static: true }
],
    sm: [
        { i: "1", x: 0, y: 0, w: 3, h: 3, static: true },
        // { i: "2", x: 0, y: 3, w: 6, h: 1, static: true },
        { i: "2", x: 0, y: 3, w: 1, h: 3, static: true },
        { i: "3", x: 1, y: 3, w: 1, h: 3, static: true },
        { i: "4", x: 2, y: 3, w: 1, h: 3, static: true },

        { i: "7", x: 0, y: 6, w: 3, h: 2.5, static: true },
        { i: "8", x: 0, y: 8.5, w: 3, h: 2.5, static: true },

        { i: "10", x: 0, y: 11, w: 1.5, h: 3, static: true },
        { i: "11", x: 1.5, y: 11, w: 1.5, h: 3, static: true },


        { i: "5", x: 0, y: 14, w: 1.5, h: 3, static: true },
        { i: "6", x: 1.5, y: 14, w: 1.5, h: 3, static: true },

        { i: "9", x: 0, y: 17, w: 3, h: 4, static: true },

    ],
    xs: [{ i: "1", x: 0, y: 0, w: 3, h: 3, static: true },
    // { i: "2", x: 0, y: 3, w: 6, h: 1, static: true },
    { i: "2", x: 0, y: 3, w: 1, h: 2.2, static: true },
    { i: "3", x: 1, y: 3, w: 1, h: 2.2, static: true },
    { i: "4", x: 2, y: 3, w: 1, h: 2.2, static: true },

    { i: "7", x: 0, y: 5.2, w: 3, h: 2.5, static: true },
    { i: "8", x: 0, y: 7.7, w: 3, h: 2.5, static: true },

    { i: "10", x: 0, y: 10.2, w: 1.5, h: 2, static: true },
    { i: "11", x: 1.5, y: 10.2, w: 1.5, h: 2, static: true },

    
    { i: "5", x: 0, y: 12.2, w: 1.5, h: 2, static: true },
    { i: "6", x: 1.5, y: 12.2, w: 1.5, h: 2, static: true },
    
    { i: "9", x: 0, y: 14.2, w: 3, h: 4, static: true },
    ]
}

function chartResponsiveness(type = null) {
    if (window.screen.availWidth <= 768) {
        let chartConfig = {
            "gaugeouterradius": 40,
            "gaugeinnerradius": 35,
            // "valueFontSize": 14,
            "valueX": 49.5,
            "valueY": 47,

        }
        return chartConfig;
    }
    else if (window.screen.availWidth <= 845) {
        let chartConfig = {
            "gaugeouterradius": 45,
            "gaugeinnerradius": 40,
            // "valueFontSize": 18,
            "valueX": 122.5,
            "valueY": 46,
        }
        return chartConfig;
    }
    else if (window.screen.availWidth <= 1180) {
        let chartConfig = {
            "gaugeouterradius": 45,
            "gaugeinnerradius": 40,
            // "valueFontSize": 18,
            "valueX": 102,
            "valueY": 43.5,
        }
        return chartConfig;
    }
    else {
        let chartConfig = {
            "gaugeouterradius": 80,
            "gaugeinnerradius": 75,
            // "valueFontSize": 20,
            "valueX": 103,
            "valueY": type === "parts" ? 120.5 : 90,
        }
        return chartConfig;
    }
}

export const OEEWidgetProp = {
    widgetId: 2,
    manifestationId: "1",
    kpiId: "29_dashboardKpiMenu",
    type: "DctSingleValue",
    generalSettings: {
        "visualType": "doughnut2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,

    },
    name: {
        "textNo": "",
        "text": "OEE"
    }
}

export const PerformanceWidgetProp = {
    widgetId: 3,
    manifestationId: "1",
    kpiId: "28_dashboardKpiMenu",
    type: "DctSingleValue",
    generalSettings: {
        "visualType": "doughnut2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,

    },
    name: {
        "textNo": "",
        "text": "Performance"
    }
}

export const QualityWidgetProp = {
    widgetId: 4,
    manifestationId: "1",
    kpiId: "30_dashboardKpiMenu",
    type: "DctSingleValue",
    generalSettings: {
        "visualType": "doughnut2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,

    },
    name: {
        "textNo": "",
        "text": "Quality"
    }
}

export const PartsTotalWidgetProp = {
    widgetId: 5,
    manifestationId: "1",
    kpiId: "35_dashboardKpiMenu",
    type: "DctSingleValue",
    generalSettings: {
        "visualType": "doughnut2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,


    },
    name: {
        "textNo": "",
        "text": "Parts Count"
    }
}

export const PartsOverTimeWidgetProp = {
    widgetId: 6,
    manifestationId: "1",
    kpiId: "33_dashboardKpiMenu",
    type: "DctValueOverTime",
    generalSettings: {
        "visualType": "column2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "showChartLabel": true,
        "showYAxisValues": false,
        "colorPalette": undefined
    },
    name: {
        "textNo": "",
        "text": "Parts Over Time"
    }
}

export const AvailabilityWidgetProp = {
    widgetId: 7,
    manifestationId: "1",
    kpiId: "31_dashboardKpiMenu",
    type: "DctKeyValueList",
    generalSettings: {
        "visualType": "stackedbar2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "legendPosition": "top-right",
        "showLegend": true,
        "hideWidgetTitle": true
        // "gaugeouterradius":window.screen.availWidth > 768 ? 80: 50,
        // "gaugeouterradius":window.screen.availWidth > 768 ? 80: 50,
    },
    name: {
        "textNo": "",
        "text": "Availability"
    }
}

export const EventWidgetProp = {
    widgetId: 7,
    manifestationId: "1",
    kpiId: "31_dashboardKpiMenu",
    type: "DctKeyValueList",
    generalSettings: {
        "visualType": "stackedbar2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "hideWidgetTitle": true
        // "gaugeouterradius": 50,
        // "gaugeouterradius": 60,
    },
    name: {
        "textNo": "99003240",
        "text": "Event"
    }
}

export const DownTimeWidgetProp = {
    widgetId: 8,
    manifestationId: "1",
    kpiId: "32_dashboardKpiMenu",
    type: "DctKeyValueList",
    generalSettings: {
        "visualType": "stackedbar2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "legendPosition": "top-right",
        "showLegend": true,
        "hideWidgetTitle": true
        // "gaugeouterradius":window.screen.availWidth > 768 ? 80: 50,
        // "gaugeouterradius":window.screen.availWidth > 768 ? 80: 50,
    },
    name: {
        "textNo": "",
        "text": "Downtime Reasons"
    }
}


export const ProductionAmountPerHourWidgetProp = {
    widgetId: 9,
    manifestationId: "1",
    kpiId: "34_dashboardKpiMenu",
    type: "DctKeyValueList",
    generalSettings:
    {
        "visualType": "hourlyheatmap",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "showChartLabel": true,
        "labelDisplay": "stagger",
        "switchColumsRows": true,
        "heatMapGradient": true,
        "mapByPercent": true,
        "adjustRange": true,
        "valueFontSize": "10",
        "showLegend": true,
        "showValues": true,
        // "redMaxField": "70",
        // "greenMaxField": "375",
        // "yellowMaxField": "180",

        "legendPosition": "top-right",
        "hideWidgetTitle": true

    }
    ,
    name: {
        "textNo": "",
        "text": "Production Amount per Hour"
    }
}
export const DowntimeReasonsDurationWidgetProp = {
    widgetId: 10,
    manifestationId: "1",
    kpiId: "36_dashboardKpiMenu",
    type: "DctValueOverTime",
    generalSettings:
    {
        "visualType": "pareto2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "showChartLabel": true,
        "showYAxisValues": false,
        "showValues": true,
        "labelDisplay":"stagger"
    }
    ,
    name: {
        "textNo": "",
        "text": "Downtime Reasons: Duration"
    }
}
export const DowntimeReasonsCountWidgetProp = {
    widgetId: 11,
    manifestationId: "1",
    kpiId: "37_dashboardKpiMenu",
    type: "DctValueOverTime",
    generalSettings:
    {
        "visualType": "pareto2d",
        "hideExportIcon": true,
        "hideCaptionFields": true,
        "hideSubCaptionFields": true,
        "showChartLabel": true,
        "showYAxisValues": false,
        "showValues": true,
        "labelDisplay":'stagger'
    }
    ,
    name: {
        "textNo": "",
        "text": "Downtime Reasons: Count"
    }
}

//Function for converting the server time date to be readable in UI
export const formatDate = (date, newFormat = false) => {
    let dateObj = new Date(date);

    let dd = dateObj.getDate();
    let mm = dateObj.getMonth() + 1; // January is 0!
    let yyyy = dateObj.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    //dateObj = yyyy + '-' + mm + '-' + dd + 'T' + hour + ':' + min + ':' + sec + 'Z';

    let dateStr = "";
    if (!newFormat) {
        dateStr = yyyy + '/' + mm + '/' + dd;
    }
    else {
        dateStr = dd + '/' + mm + '/' + yyyy;
    }

    if (newFormat === "0") {
        dateStr = yyyy + '-' + mm + '-' + dd;
    }
    return dateStr;
}

export const getFormattedDate = (date) => {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1;
    const day = new Date(date).getDate();

    return `${year}-${month}-${day}`
}