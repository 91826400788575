import { LiCheckBox, LiStatusManager } from 'liseccomponents'
import { LiMultiterm, getMultitermTexts } from 'lisecutilityfunctions/lib/utilitylib'
import React, { useState } from 'react'
import { useEffect } from 'react'
import * as conversionLib from 'lisecutilityfunctions/lib/commonfunction';
import { OpenApiClient_calendar_v1, OpenApiClient_analytics_v1, getSiteName } from 'lisecutilityfunctions/lib/openapi';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as FilterActions from '../actions/filteractions'
import useWindowSize from '../hooks/useWindowSize';

const siteName = getSiteName()

function Shifts(props) {

    const [shifts, setShifts] = useState([])
    const [selectedShifts, setSelectedShifts] = useState([])
    const [shiftText, setShiftText] = useState("Shift")
    const size = useWindowSize()

    

    useEffect(() => {
        async function getTranslation () {
            var tempArrayofText = await getMultitermTexts(["09000821"])
            let texts = conversionLib.convertToJSON(tempArrayofText.state.response.data)
            setShiftText(texts[0].text);
        }
        getTranslation()
        if(props?.filterStore?.selectedDate && props?.filterStore?.selectedMachine){
            if (!props.isMobile && size.width > 1023) {
                makeShiftsCall()
            }
            else if (props.isMobile && size.width <= 1023) {
                makeShiftsCall()
            }else{
                makeShiftsCall()
            }
        }   
    }, [props.filterStore.selectedDate, props.filterStore.selectedMachine])

    const makeShiftsCall = () => {
        if (isNaN(parseInt(props.filterStore.selectedMachine))) {
            OpenApiClient_analytics_v1.getClient(siteName).GET_Shift_machine_siteId_machineId(callbackShits, 1, props.filterStore.selectedMachine, props.filterStore.selectedDate)
        }
        else {
            OpenApiClient_analytics_v1.getClient(siteName).GET_Shift_machineGroup_siteId_groupId(callbackShits, 1, props.filterStore.selectedMachine, props.filterStore.selectedDate)
        }
    }


    const callbackShits = (responseData) => {
        if (responseData.state.response.status === 200) {
            let jsonData = conversionLib.convertToJSON(responseData.state.response.data);
            setShifts(jsonData)
            props.setShiftsList(jsonData)
            setSelectedShifts([jsonData[0]])
            props.setShifts([jsonData[0]])
        }
        else if (responseData.state.response.status === 204) {
            setShifts([])
            setSelectedShifts([])
            props.setShifts([])
            props.setShiftsList([])
            // let errorData = conversionLib.convertToJSON(responseData.state.response.data);
            // LiStatusManager.showStatus(responseData.state.response.status, errorData.title);
        }
    }

    const selectShifts = (ev, shift) => {
        if (ev.target.checked) {

            addShift(shift)
        }
        else {
            removeShift(shift)
        }
    }

    function removeShift(shift) {
        let index = selectedShifts.findIndex((item) => {
            return item === shift
        })
        let cloneSelectedShift = [...selectedShifts]
        cloneSelectedShift.splice(index, 1)
        setSelectedShifts(cloneSelectedShift)
        props.setShifts(cloneSelectedShift)
        
    }


    function addShift(shift) {
        let prevselectShifts = [shift]
        setSelectedShifts(prevselectShifts)
        props.setShifts(prevselectShifts)
    }

    const selectShiftsMobile = (ev, shift) => {
        if (selectShifts.includes(shift)) {
            removeShift(shift)
        }
        else {
            addShift(shift)
        }
        props.onShiftSelected()
    }

    return (
        <div className="OEE_FilterShifts">
            {
                props.isMobile ? <span className="OEE_FilterShifts_Heading">Shifts</span> : null
            }

            {
                props.filterStore.shiftsList.map((eachShift, index) => {
                    const shift = `${shiftText} ${eachShift}`
                    if (props.isMobile) {
                        return (
                            <div className={"OEE_FilterShifts_Mobile" + (props.filterStore.selectedShifts.includes(eachShift) ? " active" : "")} key={index} onClick={(ev) => selectShiftsMobile(ev, eachShift)}>
                                <LiMultiterm textId="" textVal={shift} />
                            </div>

                        )
                    }
                    else {
                        return (
                            <LiCheckBox key={index} LiCheckBoxID={`shiftindex${index}`} LiCheckLabel={<LiMultiterm textId="" textVal={shift} />} LiCheckBoxChecked={props.filterStore.selectedShifts.includes(eachShift) ? true : false} LiCheckOnChange={(ev) => selectShifts(ev, eachShift)} />
                        )
                    }
                })
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        filterStore: state.filterStore
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setShifts: FilterActions.setSelectedShifts,
        setShiftsList : FilterActions.setShiftsList
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
